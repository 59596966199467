<template>
  <v-row justify="start">
    <v-dialog
      v-model="dialogCreate"
      scrollable
      persistent
      max-width="700px"
    >
      <v-card
        class="rounded-max hide_overflow"
        flat
      >
        <div class="closer d-flex justify-end pa-1">
          <v-btn
            icon
            small
            @click="$emit('close')"
          >
            <v-icon small>
              mdi-close
            </v-icon>
          </v-btn>
        </div>
        <v-card-text class="mx-0">
          <v-container>
            <h3
              class="px-5 color_default"
            >
              Tambah Fasyankes {{ tabName }}
            </h3>
            <div
              class="py-3 px-5"
            >
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    v-model="input_name"
                    :label="'Nama ' + tabName"
                    color="primary"
                    dense
                    placeholder="Ketik Disini"
                    outlined
                  />
                  <v-text-field
                    v-model="input_code"
                    :label="'Kode ' + tabName"
                    color="primary"
                    dense
                    placeholder="Ketik Disini"
                    outlined
                  />
                </v-col>
                <v-col cols="6">
                  <div>
                    <div class="mb-7">
                      <v-select
                        v-model="selected_province_id"
                        placeholder="pilih Provinsi"
                        item-text="provinsi_name"
                        item-value="provinsi_id"
                        :disabled="access_right == 'Dinkes Provinsi'"
                        :items="provinces"
                        dense
                        outlined
                        label="Pilih Provinsi"
                        hide-details="auto"
                        @change="getRegencies()"
                      />
                    </div>

                    <div class="mb-7">
                      <v-select
                        v-model="selected_regency_id"
                        placeholder="pilih Kabupaten/kota"
                        item-text="kabupaten_name"
                        item-value="kabupatenKota_id"
                        :items="regencies"
                        :loading="loading"
                        :disabled="!city"
                        dense
                        outlined
                        label="Pilih Kabupaten/kota"
                        hide-details="auto"
                      />
                    </div>

                    <!-- submit -->
                    <div class="d-flex justify-end">
                      <v-btn
                        :disabled="isButtonDisabled()"
                        color="primary"
                        @click="createFacilitator()"
                      >
                        Submit
                      </v-btn>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </div>
            <div />
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
import Cookies from "js-cookie";
import Swal from "sweetalert2";

export default {
  name: "DialogCreateFasyankesUser",
  props: {
    dialogCreate: {
      type: Boolean,
      default: false,
    },
    tabName: {
      type: String,
      default: "",
    },
  },
   data() {
    return {
      loading: false,
      provinces: [],
      regencies: [],
      selected_province_id: null,
      selected_regency_id: null,
      input_name: "",
      input_code: "",
    };
  },
  computed: {
    ...mapState({
      err: (state) => state.auth.errMsg,
      solve: (state) => state.auth.response,
      province: (state) => state.region.province,
      city: (state) => state.region.city,
      access_right: (state) => state.role,
    }),
  },
  watch: {
    selected_province_id(val) {
      this.selected_regency_id = val;
      this.getRegencies();
    },
  },
  mounted() {
    if (this.access_right == "Dinkes Provinsi") {
      this.getProfile();
    } else {
      this.getProvinces();
    }
  },
  methods: {
    isButtonDisabled() {
      return (
        !this.input_name ||
        !this.input_code ||
        !this.selected_province_id ||
        !this.selected_regency_id
      );
    },
    getProvinces() {
      this.$store
        .dispatch("region/getProvince")
        .then((res) => {
          this.provinces = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getProfile() {
      this.loading = true;
      let id = Cookies.get("id");
      this.$store
        .dispatch("user/getProfile", id)
        .then((data) => {
          this.date = this.$date().format("YYYY-MM");
          let user = data.data;
          this.userProv = user.provinsi;
          this.getProvinces();
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
          this.Swal("error", err.message);
        });
    },
    getRegencies() {
      let data = {
        provinsi_id: this.selected_province_id,
      };
      this.loading = true;
      this.$store
        .dispatch("region/getCity", data)
        .then((res) => {
          this.regencies = res.data;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
   
    getInstansi(id) {
      this.getRs(id);
      this.getPuskesmas(id);
    },
    getRs(id) {
      this.$store
        .dispatch("region/getRs", id)
        .then((data) => {
          console.log(data);
          this.rs = data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getPuskesmas(id) {
      this.$store
        .dispatch("region/getPuskesmas", id)
        .then((data) => {
          console.log(data);
          this.puskesmas = data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    createFacilitator() {
      const data = {
        facilitator_type: this.tabName == "Rumah Sakit" ? 1 : 2,
        name: this.input_name,
        code: this.input_code,
        province_id: this.selected_province_id,
        regency_id: this.selected_regency_id,
      };

      this.$store.dispatch('user/createFacilitator', data)
        .then((res) => {
          this.Swal("success", "Berhasil menambahkan data");
          this.$emit("close");
          this.$emit("refresh");

          if (this.tabName == "Rumah Sakit") {
            this.getRs();
          } else {
            this.getPuskesmas();
          }
        })
        .catch((err) => {
          this.Swal("error", "Terjadi kesalahan");
        });
    },
    Swal(type, msg) {
      Swal.fire({
        icon: type,
        title: msg,
        showConfirmButton: false,
        position: "top-end",
        timer: 3000,
        toast: true,
        customClass: {
          title: "family",
        },
      });
    },
  }
};
</script>

<style scoped>
.v-label {
  font-size: small;
}
.side_left {
  width: 40%;
  height: 400px;
  border-radius: 0 !important;
}
.side_right {
  width: 60%;
}
.closer {
  width: 100%;
}
.temp_form {
  padding: 20px 25px;
}
.hide_overflow {
  overflow: hidden;
}
.family {
  font-family: Arial, Helvetica, sans-serif;
}
.small-txt {
  font-size: small !important;
}
.strip_ {
  padding: 10px;
  cursor: pointer;
}
.strip_:hover {
  background: #fbfbfb;
}
.temp_list {
  height: 300px;
  overflow: hidden;
  overflow-y: scroll;
}
</style>
